//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClickOutside from 'vue-click-outside';
import { mapState, mapGetters } from 'vuex';

import {
  faHeart,
  faSignIn,
  faBars,
  faGlobe,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faUser,
  faShoppingCart,
} from '@motionelements/assets/src/js/me-font/icons-fa6';

import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { faSparkle } from '@fortawesome/pro-solid-svg-icons';

import featureFlagMixin from '@motionelements/core/src/components/mixins/feature-flag.mixin.js';
import authMixin from '@motionelements/core/src/components/mixins/auth.mixin.js';
import studioAiMixin from '@motionelements/core/src/components/mixins/studio-ai.mixin.js';

import NavRecentlyVisitedList from '@motionelements/core/src/components/nav/NavRecentlyVisitedList.vue';
import NavLink from '@motionelements/core/src/components/nav/NavLink.vue';
import NavLogo from './NavLogo.vue';
import MenuLanguageNavDropdown from './MenuLanguageNavDropdown.vue';
import UserNavDropdown from './UserNavDropdown.vue';
import CartNavDropdown from './CartNavDropdown.vue';

export default {
  name: 'TheNavBarDesktop',
  props: {
    isTransparent: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [featureFlagMixin, authMixin, studioAiMixin],
  directives: {
    ClickOutside,
  },
  components: {
    NuxtClientOnly: () => import('./NuxtClientOnly.vue'),
    // NavLink: () => import('./NavLink.vue'),
    ExploreMenu: () => import('./ExploreMenu.vue'),
    NavLink,
    NavLogo,
    MenuLanguageNavDropdown,
    UserNavDropdown,
    CartNavDropdown,
    NavRecentlyVisitedList,
  },
  data() {
    return {
      isExploreMenuVisible: false,
      icons: {
        faBars,
        faHeart,
        faTimes,
        faSignIn,
        faGlobe,
        faShoppingCart,
        faUser,
        faSparkle,
      },
    };
  },
  watch: {
    '$route'() {
      // console.log('========== WATCH $route ', val, oldVal);
      this.isExploreMenuVisible = false;
    },
  },
  computed: {
    ...mapState({
      // favoritesDefaultCount: state => state.favorites && state.favorites.defaultFolder.totalCount,
      isLoggedIn: state => state.user.isLoggedIn,
      addedToday: state => state.favorites.addedToday,
    }),
    ...mapGetters({
      isMember: 'user/isMember',
    }),
    isTransparentBg() {
      return this.isTransparent && !this.isExploreMenuVisible;
    },
    showUnlimitedSubscriptionLink() {
      return !this.isLoggedIn;
    },
  },
  methods: {
    hideExploreMenu() {
      this.isExploreMenuVisible = false;
    },
  },
};
