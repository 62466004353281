//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import channelTypesMixin from '@motionelements/core/src/components/mixins/channelTypes.mixin.js';

export default {
  name: 'RecentlyVisitedList',
  props: {
    showItems: {
      type: Number,
      default: 3,
    },
  },
  mixins: [channelTypesMixin],
  components: {
    NavLink: () => import('@motionelements/core/src/components/nav/NavLink.vue'),
  },
  watch: {
    '$route'() {
      // console.log('========== WATCH $route ', val, oldVal);
      this.updateRecentlyVisited();
    },
  },
  computed: {
    ...mapState({
      recentlyVisited: state => state.app.navbarRecentlyVisited,
    }),
    recentlyVisitedList() {
      return [...this.recentlyVisited].splice(0, this.showItems);
    },
  },
  methods: {
    updateRecentlyVisited() {
      let channel;
      if (_.get(this.$route.params, 'channel')) { // channel page
        channel = this.channelTypesFiltered.find(x => x.link === `/${_.get(this.$route.params, 'channel')}`);
      }
      if (_.get(this.$route.params, 'mediatype')) { // search page
        channel = this.channelTypesFiltered.find(x => x.id === _.get(this.$route.params, 'mediatype'));
      }
      if (channel) this.$store.commit('app/addNavbarRecentlyVisited', channel);
    },
  },
  mounted() {
    this.updateRecentlyVisited();
  },
};
